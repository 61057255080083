import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import { first } from 'rxjs/operators';
import {formatDate } from '@angular/common';
import {environment} from '../../environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-ci',
  templateUrl: './ci.component.html',
  styleUrls: ['./ci.component.scss']
})
export class CiComponent implements OnInit {
  public loading=false;
  CiID:any;
  dataIsNull:boolean=true;
  dataloading:boolean=false;
  htmlContent:any;
  checkList:any;
  public ciValidation: any;
  projectGuid:any;
  project:any;
  ci:any;  
  loadfields:boolean=false;
  loadUserDetails: boolean=false;
  ciCheck:boolean=false;
  dateTime= new Date();
  user:any;
  reCaptchaKey:string;
  captcha:any;
  constructor( private svApi: ApiService, private svData: DataService,private route: Router) {
    this.loading=true;
    this.reCaptchaKey=environment.RecaptchaV2;
    if (sessionStorage.getItem("ciCheck"))
    {
      this.ciCheck=true;
      // if ciCheck,  then get user details (for put the value in date ciCheck)
      this.svApi.getUserInputByID(sessionStorage.getItem("userID")).subscribe(
        data=>{
          this.user=data;
          //console.log("user: ", this.user)
          this.loadUserDetails=true;
        },
        error=>
        {
          console.log("error get user")
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: '' + error,
          })
        }
      )
      this.loading=false;
    }
      this.projectGuid=sessionStorage.getItem('project');
      //console.log("projectGuid",this.projectGuid)
      this.svApi.getProjectByGuid(this.projectGuid).subscribe(
      data=>{
      
        if(data==null)
        {
          this.dataIsNull=true;
          this.dataloading=true;
          this.loading=false;
        }
        else{
          this.dataIsNull=false;
          this.project=data;
         // console.log("project: ",this.project)
          this.svApi.getCIByProject(this.project.projectID).subscribe(
            data=>{
              this.checkList=JSON.parse(data[0].checkList);
             // console.log("this.checkList: ", this.checkList );
              this.htmlContent=data[0].html;
              this.CiID=data[0].ciID;
              this.loadfields=true;   
              this.loading=false;        
            },
            error=>{
              this.loading=false;
              Swal.fire({
                icon: 'error',
                title: 'Erro! ',
                text: '' + error,
              })
            }
          )
        }
      },
      error=>{
        this.loading=false;
        this.dataIsNull=true;
        this.dataloading=true;
        Swal.fire({
          icon: 'error',
          title: 'Projeto não disponível',
          text: '',
        })

      }
      )

  
    

  }

  ngOnInit(): void {
  }
  
  public downloadAsPDF() {
    this.loading=true;
    this.svApi.getDocumentByCI(this.CiID).subscribe(
      data=>
      {
        let x:any=data;
        if(x.length!=0){
          let name=data[0].name;
          this.svApi.getPDFByDocumentID(data[0].documentID).subscribe(
            x=>{
         
            var newBlob = new Blob([x], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }
                // For other browsers: 
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                link.download = name;
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));  
            });
            this.loading=false;
        }
        else{
          this.loading=false;
          Swal.fire({
            icon: 'info',
            title: 'Não tem nenhum documento anexado! ',
            text: ''
          })
        }   
      },
      error=>{
        this.loading=false;
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: '' + error,
        })
      }
    );
  }
 

   haveFalse(element, index, array) {   //verify if any value is false
    return element ==false;
  }

  haveUndefined(element, index, array) {   //verify if any value is false
    return element ==undefined;
  }



  checkClick(value, i){
    let list = this.checkList;
    list[i].check=!value;
    let array:any=[]
    for(let check of this.checkList)
    {
      array.push(check.check)
    }
    if(array.some(this.haveFalse)==false && array.some(this.haveUndefined)==false) 
    {
      this.ciValidation=true;
    }
    else{
      this.ciValidation=false;
    }

  }

  submit(){
    this.loading=true;
   let date= formatDate(this.dateTime, 'yyyy-MM-dd', 'en-US');
   // console.log(date)
     let user={userID: "", ciCheck: "true", dateCiCheck: date};
 
    //If submit ci -> create a new, save the id of new user in sessionStorage and go home
    this.svApi.addUserInput(user).pipe(first()).subscribe(
      data=> {
        let result:any=data;
        sessionStorage.setItem("userID", result.userInputID)
        sessionStorage.setItem("ciCheck", "true")
        this.loading=false;
        this.route.navigate(['home'])
      },
      error=>
      {
        this.loading=false;
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: '' + error,
        })
      }
    );

  }

}
