<div #scrollDiv class="content">
  <div class="row justify-content-center" style="margin-right:0px; margin-left: 0px;">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 noPadding">
      
      <div class="container noPadding">
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 noPadding">

            <!-- box header form : title, subtitle, author, description-->
            <div class="row justify-content-center blueColor">
              <div class="col-12 titleDiv">
              <div class="row">
                  <h3> <i class="far fa-file-alt iconyellow" style="font-size: 30px; margin-left: 1rem;margin-right: 0.5rem;"></i>{{formData.title}}</h3>
              </div>
              <div class="row" style="padding-left: 1rem; ">
                <p style="color:#5E5D5D; font-weight: bold;">{{formData.subtitle}}</p>
              </div>
              <div class="row " style="padding-left: 1rem;">
                <p style="color:#5E5D5D;">{{formData.authors}}</p>
              </div>
              <div class="row">
                  <div class="col cDesc" style="padding-top:1rem !important;">
                  {{formData.description}}
                 </div>
              </div>
              </div>
          </div>
        <!--  -->
<!-- <div *ngIf="end">
  {{formData.config[0].msg1}}  {{formData.config[0].numberNot}}  {{formData.config[0].msg2}}
</div> -->
            <div class="row" style="margin-top: 8rem;">
              <div class="ftheme">
                <div class="col-12 noPadding">

                  <!-- call app builder component pass questions -->
                  <app-form-builder [submitted]="submitted" [itemQuestions]="formData.attributes" (change)="limit()"  *ngIf="haveLimit"></app-form-builder>
                  <app-form-builder [submitted]="submitted" [itemQuestions]="formData.attributes" *ngIf="!haveLimit"></app-form-builder>
                
                  <!-- reCaptcha 
                  <div class="btn-cont">
                    <re-captcha [(ngModel)]="captcha" name="captcha" required siteKey="{{reCaptchaKey}}"
                      #captchaControl="ngModel" [required]="true"></re-captcha>
                    <div [hidden]="captchaProtectedForm.form.valid" style="text-align: left">Por favor, confirme o reCaptcha</div>
                    <div class="btn-cont">
                      <input *ngIf="!captchaProtectedForm.form.valid" type="button" (click)="submit()" value="SUBMETER"
                        class="btn btn-primary btSubmit" id="submit" disabled="disabled">
                      <input *ngIf="captchaProtectedForm.form.valid" type="button" (click)="submit()" value="SUBMETER"
                        class="btn btn-primary btSubmit" id="submit">
                    </div>
                  </div>-->

                </div>
              </div>
            </div>
          
            <div #scrollDivSubmit class="row">
              <div class="col-12" style="text-align: left; padding-bottom: 1rem; padding-top:2rem">
                <button *ngIf="submitted==false && btSubmit" class="btForm btFormBack" (click)="exitForm()"> SAIR </button>
                <button *ngIf="submitted==false && btSubmit" style="margin-left: 1.5rem;  font-weight: bold;" id="submit" class="btForm" (click)="submit()"> <i class="fas fa-paper-plane"></i> SUBMETER</button>
                <button *ngIf="submitted==true" class="btForm btFormBack" [routerLink]='["/"]'> CONTINUAR </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-loading [show]="!loadfields" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>