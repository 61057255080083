import { Component, Input, OnInit, TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-form-radio-child',
    template: `
    <div class="box">
    <i class="fas fa-list-ul yellow faIcon paddingIconR"></i><label class="questionLbl">{{item.label}} <label *ngIf="item.required" class="text-danger">*</label></label>
    <button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80;" 
    tooltip="{{item.help}}">
    <i class="far fa-question-circle"></i>
</button>
    <p>
    <img *ngIf="item.haveImage" [src]="item.ImageBase64" (click)="openImage(template, item.ImageBase64)" style="max-width: 300px;max-height: 300px;cursor:zoom-in;" class="img-responsive">

    </p>
    <div *ngFor="let v of item.values" class="inline-form-group OpRadioCheck vertical-align boxRandC">
    <label class="cust-check-bx" style="margin-bottom:0px; color: #5E5D5D;">
    <input type="radio" [value]="v.value" [disabled]="submitted" name="{{item.name}}" [(ngModel)]="item.value" required
    [ngModelOptions]="{standalone: true}">
    <img *ngIf="v.haveImage" [src]="v.ImageBase64" (click)="openImage(template, v.ImageBase64)" class="img-responsive imgView">
    {{v.label}} <span class="checkmark"></span> </label> </div>
    </div>
    <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Image</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="font-size: 2rem;
        font-weight: initial;">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img [src]="imageModel" style=" max-height: 100%;max-width: 100%;cursor: pointer; ">
    </div>
    <div class="modal-footer">

    </div>
   
  </ng-template>
    `,
    styleUrls: ['../elementsStyle.scss'],
    providers: []
})

export class RadioChildComponent implements OnInit {

    
    @Input() item: any;
    @Input() itemRequired: any;
    @Input() itemName: any;
    @Input() itemLabel: any;
    @Input() itemMax: any;
    @Input() itemMin: any;
    @Input() itemPlaceholder: any;
    @Input() submitted: boolean;
    template: TemplateRef<any>;
    modalRef: BsModalRef;
    imageModel:any;

    constructor(private modalService: BsModalService) { }

    ngOnInit() {

    }

    openImage(template: TemplateRef<any>, image) 
    {
        this.imageModel=image;
            this.modalRef = this.modalService.show(
              template
            );
    }

}