import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-element',
    templateUrl: './form-element.component.html',
    styleUrls: ['../../app.component.scss'],
    providers: []
})

export class FormElementComponent implements OnInit {

    @Input() item: any;
    @Input() itemType: any;
    @Input() submitted: boolean;

    constructor() { }

    ngOnInit() {
    }

}