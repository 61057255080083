import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private msgForm = new BehaviorSubject('default');
    currentmsgForm = this.msgForm.asObservable();

    changeForm(newForm: any) {
      this.msgForm.next(newForm);
  }  
}
