import { Component, OnInit } from '@angular/core';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {
  load:boolean=false;
  projectGuid:any;
  project:any;
  listForms:any;  
  change:boolean=false;
  userID:any;
  listFormsUserFill:any;
  todayDate: any;
  projectVisible:boolean=false;
  constructor(private svApi: ApiService, private svData: DataService, private router: Router) { }

  ngOnInit(): void {
    this.todayDate=formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    
   
    this.projectGuid=sessionStorage.getItem('project');
    this.userID=sessionStorage.getItem('userID');

    this.svApi.getProjectByGuid(this.projectGuid).subscribe(
      data=>{
        this.project=data;
        this.project.startDate=formatDate(this.project.startDate, 'yyyy-MM-dd', 'en-US');
      this.project.endDate=formatDate(this.project.endDate, 'yyyy-MM-dd', 'en-US');

        if(this.project.startDate<=this.todayDate &&this.project.endDate>=this.todayDate)
        {
          this.projectVisible=true;
         this.svApi.getFormByProjectID(this.project.projectID).subscribe(
          data=>{
            
            this.listForms=data;
            
            for(let f of this.listForms)
            {
              if(!f.dependenceForms || f.dependenceForms=="00")
              {
                f.visible=true;
              }
            }
          
            this.svApi.getUserInputFormByUserID(this.userID).subscribe(
              data=>{
         
                this.listFormsUserFill=data;
                for(let f of this.listForms)
                {
                  for(let fUser of this.listFormsUserFill)
                  {
                    if(f.formID==fUser.formID)
                    {
                      f.fill=true;
                    }
                    if(f.dependenceForms==fUser.formID)
                    {
                
                      f.visible=true;
                    }
                  }
                  this.load=true;
                }
              
              
              },
              error=>{
                console.log("error to find inputForm by ID")
              }
            )
           
          },
          error=>{
            console.log("Erro: ", error)
          }
        )
         
      }
      else{
        this.projectVisible=false;
        this.load=true;
      }
      },
      error=>{
        console.log("Erro: ", error)
      }

    )
  }
  goForm(formID:any)
  {
      this.svData.changeForm(formID);

      this.router.navigate(['/form']);
  }

  changeCss()
  {
    this.change=!this.change;
  }

}
