<!-- HEADER -->
<header class="header">
    <div class="container" style="margin-left: 0 auto;">
      <div class="row" style="padding-top: 1.5rem; padding-bottom: 1rem;">
      <div class="col" style="display: flex; align-items: center; flex-wrap: wrap;">
        <img style="width: 200px;" src="assets/img/logo-projeto-header-PT.svg" />
      </div>
  
      <div class="col" style="display: flex; align-items: center; flex-wrap: wrap;">      
        <div style="text-align: right;width: 100%;">
         
        </div>
      </div>
    </div>
    <div class="yellowcircle">
    </div>
    </div>
  </header>
  
  <!-- HEADER END -->
  
  <!-- BODY -->
  <div class="container noPadding"  style="margin-top: 4.25rem;">
    <div class="fade-in"></div>
    <router-outlet></router-outlet>
  </div>
  <!-- BODY END  -->
  
  <footer>
    <div class="row rowHeader">
      <div class="col-12" style="text-align: center;">
        <img class="img-fluid" src="assets/img/logos-footer.png" />
      </div>
    </div>
    <div class="row rowHeader2">
      <div class="col-12" style="text-align: center; color: #233A80; font-size: 14px;line-height: 24px; margin-top: 2rem; margin-bottom: 2rem;">
        geral@prochildcolab.pt | prochildcolab.pt
      </div>
    </div>
  </footer>