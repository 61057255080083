import { ViewportScroller, DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, OnChanges, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import {FormBuilderComponent} from '../forms/form-builder.component';

import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  countNot: number=0;
  haveLimit:boolean=false;
  limitValue:any;
  limitNumber:any;
  btSubmit:boolean=true;
  end:boolean=false;
  formData: any = {};
  reCaptchaKey: string;
  //modelFields: Array<field> = [];
  userID: string;
  userInputFormID: number;
  model: any = {
    //attributes: this.modelFields
  };

  //
  private userConfigStructure: any = [];
  //
  private quotationStructure: any = [];
  // initialize answers array empty before sending to server
  private fillAnswer: any = [];
  //
  public submitted: boolean = false;
  //
  public scoreString: any = [];
  //
  formID:any
  form:any;
  data:any
  loadfields:boolean=false;

  @ViewChild("scrollDiv", { static: true }) scrollTo: ElementRef;

  constructor(
    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private _elementRef: ElementRef,
    private router: Router,
    private svApi: ApiService, private svData: DataService

  ) {
    this.userID=sessionStorage.getItem("userID");

    this.svData.currentmsgForm.subscribe(data=>{
      this.formID=data
     if(data!="default"){
        this.svApi.getFormByID(this.formID).subscribe(data=>{
          this.data=data;
          let config= JSON.parse(this.data.config);
          this.formData= JSON.parse(this.data.json) 
          
        
          if(config[0].bool==true)
          {   
            this.haveLimit=true;
            this.limitValue=config[0].value;        
            this.limitNumber=config[0].number;
          }
          for(let f of this.formData.attributes)
          {
      
          if(f.dependencies==true)
          {
            f.visible=false;
          }
          else{
            f.visible=true;
          }
          if (f.haveImage==true)
          {
           
            this.getImage(f);
          }
          if (f.type=="matrix")
          {
           
            for(let r of f.values[1].rows)
            {
              if(r.haveImage==true)
              {
                this.getImage(r);
              }
            }
          }
          else if(f.type=="checkbox"||f.type=="radio"||f.type=="autocomplete"||f.type=="orderItem")
          {
            for(let v of f.values)
            {
              if(v.haveImage==true)
              {
                this.getImage(v);
              }
            }
          }
        }
          this.loadfields=true;
        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: '' + error,
          })
        })
    }
    else{
      this.router.navigate(["/home"]);
    }
     }
      
      );

  }


 

  ngOnInit() {
    setTimeout(() => {
      this.scrollTo.nativeElement.scrollIntoView({ behavior: 'smooth' });
    });

   
    // iterate over form configs to create quotation array structure
    /*for (let config of this.formData.configs) {
      // if form as config score defined
      if (config.type == "quotation") {
        this.quotationStructure.push({
          "quotationCode": config.code,
          "quotationName": config.name,
          "cutValue": config.value,
          "messages": config.messages,
          "formula": config.formula,
          "showFeedback": config.showFeedback,
          "invertedValue": config.invertedValue
        });
        // other form configs goes here
      } else {
        this.userConfigStructure.push({
          "code": config.code, "value": config.value
        });
      }
    }
*/
    //this.fillExpressionWithValues();
  }



  //get the image of item
  getImage(item)
  {
    this.svApi.getImageByID(item.imageID).subscribe(
      data=>{
          item.ImageBase64=data[0].base64;           
      },
      error=>{
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: ''+error,
        })
      }
    )
  }

  
  //exit form
  exitForm() {
    Swal.fire({
      title: "Tem a certeza que pretende sair?",
      html: "Todas as respostas serão perdidas.",
      showDenyButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: '#41BBC9',
      denyButtonColor: '#E5004E',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      customClass: {
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/"]);
      } else if (result.isDenied) {
        // does nothing
      }
    })
  }

  // function that does the math
  parse(leftHandler, operation, righHandler) {
    var signs = ["*", "+", "-", "/"];
    var funcs = [multiply, add, minus, divison];
    var tokens = [leftHandler, operation, righHandler];
    for (var round = 0; round < signs.length; round++) {
      for (var place = 0; place < tokens.length; place++) {
        if (tokens[place] == signs[round]) {
          var a = Math.round(tokens[place - 1] * 100 + Number.EPSILON) / 100;
          var b = Math.round(tokens[place + 1] * 100 + Number.EPSILON) / 100;
          var result = funcs[round](a, b);
         
          // tokens[place - 1] = result.toString();
         
          // tokens.splice(place--, 2);
         
          tokens[0] = result;
        }
      }
    }
    return tokens[0];

    function multiply(x, y) {
      let d = x * y;
      return Math.round(d * 100 + Number.EPSILON) / 100;
    }

    function add(x, y) {
      return x + y;
    }

    function minus(x, y) {
      return x - y;
    }

    function divison(x, y) {
      let d = x / y;
      return Math.round(d * 100 + Number.EPSILON) / 100;
    }

  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  onFailScrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth", block: 'center' });
  }

  answerDefault(question, answer) {
    answer.descriptionValueAnswer = "text";
    answer.questionSynthesis = question.qSynthesis
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";     
    }
    else{
      answer.valueAnswer = question.value;
    }
    

    //answer.descriptionValueAnswer = question.value;
    return answer;
  }

  answerNumber(question, answer) {
    answer.descriptionValueAnswer = "number";
    answer.questionSynthesis = question.qSynthesis
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      answer.valueAnswer = question.value;
    }
    return answer;
  }

  answerRadioBox(questionValues, question, answer) {
    let _answers = [];
    answer.questionSynthesis = question.qSynthesis
    if(question.visible==false)
    { 
      answer.valueAnswer="NA";    
      _answers.push(answer); 
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
      _answers.push(answer);
    }
    else{
      for (let values of questionValues) {
        if (values.value == question.value) {
        // answer.valueAnswer = values.cod;
        answer.valueAnswer = values.value;
          answer.descriptionValueAnswer = values.label;
          _answers.push(answer);
        } 
      }
    }
    return _answers;
  }

  answerCheckBox(questionValues, question, answer) {
    let countSelects=0;
    let _answers=[]
    answer.questionSynthesis = question.qSynthesis;
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else{
        let iterations = questionValues.length;
        for (let values of questionValues) {
          if (values.selected == true) {
            countSelects=countSelects+1;
            //answer.valueAnswer = (values.cod + "," + answer.valueAnswer);
            answer.valueAnswer = (values.value + ";" + answer.valueAnswer);
            answer.descriptionValueAnswer = (values.label + ";" + answer.descriptionValueAnswer);
          }
        }
        if (countSelects==0)
        {
          answer.valueAnswer="Null"
        }
        if(answer.valueAnswer.lastIndexOf(";") != -1)
        {
          
          answer.valueAnswer=answer.valueAnswer.slice(0, -1);
          answer.descriptionValueAnswer=answer.descriptionValueAnswer.slice(0, -1);
        }
    }
    _answers.push(answer);
    return _answers;
  }

  answerMatrix(question,qRows, answer) {
    let _answers = [];
   
    for (let r of qRows) {
      let _answer = { ...answer }
      _answer.question = r.label;
      _answer.qSynthesis = r.qSynthesis;
      _answer.questionSynthesis = r.qSynthesis;
      _answer.questionNumber = parseInt(r.id);
  
      if(question.visible==false)
      {
        _answer.valueAnswer = "NA";
        _answer.descriptionValueAnswer = "NA";     
      }
      else if (r.valueLine == undefined && question.visible==true) {
        _answer.valueAnswer = "Null";
        _answer.descriptionValueAnswer = "Null";
      }
      else {      
        _answer.valueAnswer = r.valueLine.toString();
        _answer.descriptionValueAnswer = r.valueLine.toString();

      }
      _answers.push(_answer);
    }
    return _answers;
  }

  answerSelect(question, answer){
    answer.questionSynthesis = question.qSynthesis;
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      answer.valueAnswer = question.value;
      for (let values of question.values) {
        if (values.value == question.value) {
          answer.descriptionValueAnswer = values.label;
        }
      }
    }
    
    return answer;
  }

  answerDate(question, answer){
    answer.questionSynthesis = question.qSynthesis;
    answer.descriptionValueAnswer = "date"
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else if(question.value==null && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      const datepipe: DatePipe = new DatePipe('en-US')
      answer.valueAnswer = datepipe.transform(question.value, 'dd-MM-yyyy') 
    }
    return answer;
  }

  answerOrderItem(questionValues, question, answer)
  {
    answer.questionSynthesis = question.qSynthesis;
    if(question.visible==false)
    {
      answer.valueAnswer="NA";     
    }
    else{
      for(let v of questionValues)
    {
      answer.valueAnswer=answer.valueAnswer+";"+v.value;
      answer.descriptionValueAnswer=answer.descriptionValueAnswer+";"+v.label;
    }

    answer.valueAnswer=answer.valueAnswer.slice(1);
    answer.descriptionValueAnswer=answer.descriptionValueAnswer.slice(1);
    }

    return answer;
  }

  // check if there are dependent questions
  checkDependQuestion(questionsArr, question) {
    // 0 = no dependencie
    // 1 = has dependencie but not found match value
    // 2 = has dependencie and match value
    let code = 0;
    if (question.dependencies) {
      code = 1;
      let f = questionsArr.find(x => x.name == question.depQuestion && x.valueAnswer == question.depValue);
      if (f) {
        question.descriptionValueAnswer = f.descriptionValueAnswer;
        f.freeTextDescription = question.value;
        question.valueAnswer = question.value;
        f.freeTextValue = 1;
        code = 2;
      }
    }
    return code;
  }

  //
  fillVariablesWithValues(variableArray, answers) {
    for (let variable of variableArray) {
      let f1 = answers.find(x => x.qSynthesis == variable.value && variable.type == "question");
      if (f1) {
        variable.value = JSON.parse(f1.valueAnswer);
      }
    }
  }

  //
  fillExpressionWithValues(variableArray, expressionArray) {
    for (let expression of expressionArray) {
      let f1 = variableArray.find(x => x.code == expression.p1);
      let f2 = variableArray.find(x => x.code == expression.p2);
      if (f1) expression.p1 = JSON.parse(f1.value);
      if (f2) expression.p2 = JSON.parse(f2.value);
    }
  }

  //
  calculateExpression(expressionArray) {
    for (let expression of expressionArray) {
      let fPart = this.parse(expression.p1, expression.operation, expression.p2); // cals function that makes the math
      let f1 = expressionArray.find(x => x.p1 == expression.fPart);
      let f2 = expressionArray.find(x => x.p2 == expression.fPart);
      if (f1) {
        f1.p1 = JSON.parse(fPart);
      }
      if (f2) {
        f2.p2 = JSON.parse(fPart);
      }
      expression.fPart = JSON.parse(fPart);
    }
  }

  // to be worked, because can be multi configs
  findUserConfigInQuestion(answer) {
    let f1 = this.userConfigStructure.find(x => x.code == answer.questionSynthesis);
    if (f1) {
      f1.value = answer.valueAnswer;
    }
  }
  
  //check if number of answers X are N in sequence way 
  limit(){
    this.countNot=0;
    for(let q of this.formData.attributes)
    {
      if(this.countNot>=this.limitNumber)
      {
       break;
      }
      if(q.type=="radio" && q.visible==true){

        if (q.value==this.limitValue)
        {
          this.countNot= this.countNot+1;
        }
        else{
          this.countNot=0;
        }
      }
      else if(q.type=="matrix" && q.visible==true){       
        for (let r of q.values[1].rows)
        {
          if (parseInt(r.valueLine)==this.limitValue)
          {
            this.countNot= this.countNot+1;
          }
          else{
            this.countNot=0;
          }
          if(this.countNot>=this.limitNumber)
          {
           break;
          }
        } 
      }
    }
    if(this.countNot>=this.limitNumber)
    {
      this.end=true;
      this.btSubmit=false;
      this.submitted = false;
      this.submitForLimit();
    } 
  }

   validateEmail(email)
    {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (reg.test(email)){
    return true; }
    else{
    return false;
    }
    }
  

  submit() {
    this.btSubmit=false;
    this.submitted = false;
    this.loadfields=false;
    let valid = true;
    let validationArray = this.formData.attributes;

    validationArray.forEach(field => {
      let countTrue = 0;
      let countInvalid = 0;
   
      let countRadio = 0;
      if (field.required && !field.value && field.type != "checkbox" && field.type != "number" && field.type != "matrix" && field.visible==true) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.required && field.visible==true && field.type == "checkbox") {
        for (let v of field.values) {
          if (v.selected == true) {
            countTrue = 1;
          }

        }
        if (countTrue == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
      else if (field.type == "number" && field.required&& field.visible==true&& ((field.value < JSON.parse(field.min) || field.value > JSON.parse(field.max)) || field.value==null)) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label + '. Valor min: '+field.min+' valor max: '+field.max,
        })
        valid = false;
        return false;
      }
      else if (field.type == "matrix") {
        for (let r of field.values[1].rows) {
          if (r.valueLine != undefined) {
            countRadio = 1;
            //lblRadio = r.label;
          }
          else if(r.required==true && field.visible==true) {
            if (countInvalid == 0) {
              let _this = this;
              let xx = this._elementRef.nativeElement.querySelector("#radio_" + r.id); // to be worked, only works for radios in matrix elements
              Swal.fire({
                icon: 'error',
                title: 'Erro! Por favor complete todas as respostas!',
                text: '' + r.label,
                heightAuto: false
              }).then(function () {
                // scroll to failed question
                setTimeout(() => {
                  _this.onFailScrollToElement(xx);
                }, 500);
              })
              countInvalid++;
            }
            valid = false;
            return false;
          }
        }
      }
      
      else if(field.type== "email" && field.required && field.visible==true)
      {
        if(!this.validateEmail(field.value))
        {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Email inválido',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
   
    });
    if (!valid) {
      this.loadfields=true;
      this.btSubmit=true;
      return false;
    }
    else {
     
      this.loadfields=true;
      Swal.fire({
        title: "Tem a certeza que pretende submeter o formulário?",
        html: "Após a submissão não poderá alterar as suas respostas.",
        showDenyButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonColor: '#41BBC9',
        denyButtonColor: '#E5004E',
        confirmButtonText: "Sim",
        denyButtonText: "Não",
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        }
      }).then((result) => {
        this.loadfields=false;
        if (result.isConfirmed) {
          const datepipe: DatePipe = new DatePipe('en-US')
          let data= formatDate(new Date, 'yyyy-MM-dd', 'en-US')        
  
                // iterate over form questions
                for (let question of this.formData.attributes) {
                 
                  // answer object template for each question
                  let answer = {
                    userInputFormID:this.userInputFormID,
                    question: question.label, //check
                    questionSynthesis: "", //check
                    valueAnswer: "", //check
                    descriptionValueAnswer: "", //check
                    questionNumber: parseInt(question.id) //check,
                  };                          
                  // check for dependencie questions if found transform parent question but dont push the depQuestion to array
                // if (this.checkDependQuestion(this.fillAnswer, question) == 0) { daniel 12/03/2021

                    // if object is type number or text
                    if (question.type == "text" || question.type == "textarea"|| question.type == "email") {
                  
                      this.fillAnswer.push(this.answerDefault(question, answer));
                    }

                    else if (question.type == "autocomplete"){
                      this.fillAnswer.push(this.answerSelect(question, answer));
                    }
                    else if (question.type == "date"){
                      this.fillAnswer.push(this.answerDate(question, answer));
                    }

                    // if object is type number 
                    else if (question.type == "number") {
                      this.fillAnswer.push(this.answerNumber(question, answer));
                    }

                    // if object is type checkbox
                    else if (question.type == "checkbox") {
                      this.fillAnswer.push(...this.answerCheckBox(question.values, question, answer));
                    }
                    // if object is type radio
                    else if (question.type == "radio") { 
                      this.fillAnswer.push(...this.answerRadioBox(question.values, question, answer));
                    }
                    // if object is type orderItem
                    else if (question.type == "orderItem") { 
                      this.fillAnswer.push(this.answerOrderItem(question.values, question, answer));
                    }
                    // if object is type matrix
                    else if (question.type == "matrix") {
                      this.fillAnswer.push(...this.answerMatrix(question,question.values[1].rows, answer));
                    }       
                }  

                let userInputFormAnswers={formID: this.formID,userInputID: this.userID, date: data, answers:this.fillAnswer}  
                
                //console.log("userInputFormAnswers: ", userInputFormAnswers)

                  //inserir post do inputform que criamos
               this.svApi.addUserInputFormAndAnswers(userInputFormAnswers).pipe(first()).subscribe
               (
                 data=>
                 {
                  this.submitted = true;
                  this.loadfields=true;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "Formulário submetido com sucesso. Clique em continuar para sair.",
                    allowOutsideClick: false,
                    showConfirmButton:false,
                    timer: 7000,
                    heightAuto: false,
                  });
                 },
                 err=>
                 {
                  this.loadfields=true;
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Ocorreu um erro, por favor tente novamente.",
                    showConfirmButton: false,
                    timer: 5000,
                    heightAuto: false,
                  }); 
                  this.router.navigate(["/"]);            
                 }
               );           
        }
        else{
          this.loadfields=true;
          this.btSubmit=true;
        }
      })

    }
  }

  submitForLimit() {
    this.btSubmit=false;
    this.submitted = false;
    this.loadfields=false;
    // build final object
    let countInvalid = 0;
    let countTrue = 0;
    let countRadio = 0;
    let valid = true;
    let validationArray = this.formData.attributes;

    validationArray.forEach(field => {

      if (field.required && !field.value && field.type != "checkbox" && field.type != "number" && field.type != "matrix") {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.required && field.type == "checkbox") {
        for (let v of field.values) {
          if (v.selected == true) {
            countTrue = 1;
          }

        }
        if (countTrue == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
      else if (field.type == "number" &&  ((field.value < JSON.parse(field.min) || field.value > JSON.parse(field.max)) || field.value==null)) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.type == "matrix") {
        for (let r of field.values[1].rows) {
          if (r.valueLine != undefined) {
            countRadio = 1;
            //lblRadio = r.label;
          }
          else if(r.required==true) {
            if (countInvalid == 0) {
              let _this = this;
              let xx = this._elementRef.nativeElement.querySelector("#radio_" + r.id); // to be worked, only works for radios in matrix elements
              Swal.fire({
                icon: 'error',
                title: 'Erro! Por favor complete todas as respostas!',
                text: '' + r.label,
                heightAuto: false
              }).then(function () {
                // scroll to failed question
                setTimeout(() => {
                  _this.onFailScrollToElement(xx);
                }, 500);
              })
              countInvalid++;
            }
            valid = false;
            return false;
          }
        }
      }
      
      else if(field.type== "email" && field.required )
      {
        if(!this.validateEmail(field.value))
        {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Email inválido',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
   
    });
    if (!valid) {
      this.loadfields=true;
      this.btSubmit=true;
      return false;
    }
    else {
          const datepipe: DatePipe = new DatePipe('en-US')
          let data= formatDate(new Date, 'yyyy-MM-dd', 'en-US')
               
          // iterate over form questions
          for (let question of this.formData.attributes) 
          {
            // answer object template for each question
            let answer = {
                    userInputFormID:this.userInputFormID,
                    question: question.label, //check
                    questionSynthesis: "", //check
                    valueAnswer: "", //check
                    descriptionValueAnswer: "", //check
                    questionNumber: parseInt(question.id) //check,
            };
                   
                  // check for dependencie questions if found transform parent question but dont push the depQuestion to array
                // if (this.checkDependQuestion(this.fillAnswer, question) == 0) { daniel 12/03/2021

                    // if object is type number or text
                    if (question.type == "text" || question.type == "textarea"|| question.type == "email") {
                      this.fillAnswer.push(this.answerDefault(question, answer));
                    }

                    else if (question.type == "autocomplete"){
                      this.fillAnswer.push(this.answerSelect(question, answer));
                    }
                    else if (question.type == "date"){
                      this.fillAnswer.push(this.answerDate(question, answer));
                    }

                    // if object is type number 
                    else if (question.type == "number") {
                      this.fillAnswer.push(this.answerNumber(question, answer));
                    }

                    // if object is type checkbox
                    else if (question.type == "checkbox") {
                      this.fillAnswer.push(...this.answerCheckBox(question.values, question, answer));
                    }
                    // if object is type radio
                    else if (question.type == "radio") { 
                      this.fillAnswer.push(...this.answerRadioBox(question.values, question, answer));
                    }
                    // if object is type orderItem
                    else if (question.type == "orderItem") { 
                      this.fillAnswer.push(this.answerOrderItem(question.values, question, answer));
                    }
                    // if object is type matrix
                    else if (question.type == "matrix") {
                      this.fillAnswer.push(...this.answerMatrix(question, question.values[1].rows, answer));
                    }              
                }

                let userInputFormAnswers={formID: this.formID,userInputID: this.userID, date: data, answers:this.fillAnswer}                  
                //inserir post do inputform que criamos
               this.svApi.addUserInputFormAndAnswers(userInputFormAnswers).pipe(first()).subscribe
               (
                 data=>
                 {
                  this.submitted = true;
                  this.loadfields=true;
                  var heightPage = document.body.scrollHeight;
                  window.scrollTo(0 , heightPage);
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "O preenchimento deste questionário terminou automaticamente pela plataforma. Prossiga, por favor, para o próximo questionário. Clique em continuar para sair.",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    timer: 15000,
                    heightAuto: false,
                  });
                 },
                 err=>
                 {
                  this.loadfields=true;
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Ocorreu um erro, por favor tente novamente.",
                    showConfirmButton: false,
                    timer: 5000,
                    heightAuto: false,
                  });             
                 }
               );

                  
                  
     
          }

        }

}
