<!-- Project: {{project |json}} -->
<div *ngIf="load">
<div class="row justify-content-center blueColor">
  <div class="col-12 titleDiv">
    <div class="row">
        <div class="col cTitle ">
          {{project.name}}
        </div>
    </div>
    <div class="row">
      <div class="col cDesc">
        {{project.description}}
      </div>
  </div>
  </div>
</div>


<div class="row justify-content-center rForms">
  <div class="col-12 titleDiv" >
    <div class="row tableTitle ">
      <div class="col" style="">
        <i class="far fa-calendar-minus iconyellow" style="font-size: 30px; padding-right: 0.5rem;"></i> 
       <span>Projeto aberto de {{project.startDate}} até {{project.endDate}}</span>
      </div>

    </div>
    
    <table *ngIf="projectVisible" class="table table-striped">
      <thead>

      </thead>
      <tbody>
        <tr *ngFor="let lf of listForms">
  
          <td  *ngIf="lf.visible==true" class="" style="vertical-align: middle;"><i class="far fa-file-alt iconyellow" style="font-size: 30px; margin-left: 1rem;margin-right: 0.5rem;"></i> <span style="font-size: 18px;">{{lf.name}}</span></td>
        
          <td  *ngIf="lf.visible==true" class="" style="text-align:right;vertical-align: middle;">
            <button [hidden]="lf.fill" class="btForm" (click)="goForm(lf.formID)">Responder</button>
            <button [hidden]="!lf.fill" class="btFormDisabled" disabled><i class="fas fa-lock" style="padding-right: 5px;"></i>Respondido</button>
          </td>

          <!-- <td  class="align-center"><button class="btForm" routerLink="/form2" [hidden]="f2Complete || !f1Complete">Responder</button></td> -->
        </tr>

      </tbody>
    </table>

    <div *ngIf="projectVisible==false" class="projectUnavailable">
        <h4 style="color: grey !important;">O projeto não está disponível para preenchimento</h4>
    </div>
  </div> 
</div>

</div>

<ngx-loading [show]="!load" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>