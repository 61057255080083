import { Injectable } from '@angular/core';
import { DefaultResponse } from "../models/default-response.model";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  //Forms
  
  public getFormByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Form/"+id);
  }
  public getFormByProjectID(projectId): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Form/listFormsPublishedByProject/"+projectId);
  }

  public getCIProjectID(projectId): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Form/ciByProject/"+projectId);
  }
  //Project

  public getProjectByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Project/"+id);
  }
  public getProjectByGuid(guid): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Project/getByGuid/"+guid);
  }

  //answers

  public addAnswers(answer: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormView + "Answer/List", answer);
  }

  //userInputForm
  public getUserInputForm(): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "UserInputForm");
  }
  public addUserInputForm(userInputForm: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormView + "UserInputForm", userInputForm);
  }
 
  public getUserInputFormByUserID(userID): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "UserInputForm/ByUserInput/"+userID);
  }

  public deleteUserInputForm(userInputFormID): Observable<DefaultResponse<any>>{
    return this.http.delete<DefaultResponse<any>>(environment.ApiFormView + "UserInputForm/"+userInputFormID);
  }
   //new 2022-01-13 Post de userinputform and answer List
   public addUserInputFormAndAnswers(userInputFormAnswer: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormView + "UserInputForm/withAnswers", userInputFormAnswer);
  }
  

  //userInput
  public addUserInput(userInput: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormView + "userInput", userInput);
  }
  public getUserInputByID(userInputID: any): Observable<DefaultResponse<any>> {
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "userInput/"+userInputID);
  }

   //ci
   public getCIByProject(projectID): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "CI/ProjectCI/"+projectID);
  }
  
  public updateCI(ci: any, id): Observable<DefaultResponse<any>>{
    return this.http.put<DefaultResponse<any>>(environment.ApiFormView + "CI/"+id, ci);
  }

   //Document
   public getDocumentByCI(CiID): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Document/DocumentbyCiID/"+CiID);
  }

  public getPDFByDocumentID(DocumentID): Observable<Blob>{
    return this.http.get(environment.ApiFormView + "Document/PDFbyDocumentID/"+DocumentID, { responseType: 'blob' });

  }

  //image
  public getImageByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormView + "Image/ImageByID/"+id);
  }

}
