
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {  RouterModule } from '@angular/router';
import { HttpClientModule} from "@angular/common/http";
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
//import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import {Interceptor} from './shared/models/interceptor.module';
import { 
  EmailChildComponent, 
  EmailComponent, 
  CheckboxComponent,
  FormElementComponent, 
  MatrixComponent, 
  NumberChildComponent, 
  NumberComponent, 
  RadioChildComponent, 
  RadioComponent, 
  SubtitleComponent, 
  TextareaChildComponent, 
  TextareaComponent, 
  TextChildComponent, 
  TextComponent, 
  TitleComponent, 
  QuestiontitleComponent,
  SelectComponent,
  DateComponent} from './shared/elements';
  import { FormComponent } from './forms/form.component';
  import { FormBuilderComponent } from './forms/form-builder.component';
import { CiComponent } from './ci/ci.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OrderItensComponent } from './shared/elements/order-itens/order-itens.component';
import { ParagraphComponent } from './shared/elements/paragraph/paragraph.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    EmailChildComponent, 
  EmailComponent, 
  CheckboxComponent,
  FormElementComponent, 
  MatrixComponent, 
  NumberChildComponent, 
  NumberComponent, 
  RadioChildComponent, 
  RadioComponent, 
  SubtitleComponent, 
  TextareaChildComponent, 
  TextareaComponent, 
  TextChildComponent, 
  TextComponent, 
  TitleComponent, 
  QuestiontitleComponent,
  SelectComponent,
  DateComponent,
  FormComponent,
  FormBuilderComponent,
  CiComponent,
  OrderItensComponent,
  ParagraphComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    Interceptor,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,  
    RecaptchaFormsModule,
    RouterModule.forRoot([]),
    TooltipModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  providers: [],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
